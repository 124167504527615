import { createAction } from "redux-actions";

export const COMPANY_LIST = "COMPANY_LIST";
export const companyList = createAction(COMPANY_LIST);

export const COMPANY_DETAIL = "COMPANY_DETAIL";
export const companyDetail = createAction(COMPANY_DETAIL);

export const COMPANY_INC = "COMPANY_INC";
export const companyInc = createAction(COMPANY_INC);

export const DELETE_COMPANY = "DELETE_COMPANY";
export const deleteCompany = createAction(DELETE_COMPANY);

export const UPDATE_COMPANY_STATUS = "UPDATE_COMPANY_STATUS";
export const updateCompanyStatus = createAction(UPDATE_COMPANY_STATUS);

export const DIRECTORY_LIST = "DIRECTORY_LIST";
export const directoryList = createAction(DIRECTORY_LIST);

export const DIRECTOR_DETAIL = "DIRECTOR_DETAIL";
export const directorDetail = createAction(DIRECTOR_DETAIL);

export const FULL_CR_INFO = "FULL_CR_INFO";
export const fullCrInfo = createAction(FULL_CR_INFO);

export const COMPANY_EMPLOYEE = "COMPANY_EMPLOYEE";
export const companyEmployee = createAction(COMPANY_EMPLOYEE);

export const COMPANY_TRANSACTIONS = "COMPANY_TRANSACTIONS";
export const companyTransactions = createAction(COMPANY_TRANSACTIONS);

export const COMPANY_CARD = "COMPANY_CARD";
export const companyCard = createAction(COMPANY_CARD);

export const UPDATE_EMPLOYEE_STATUS = "UPDATE_EMPLOYEE_STATUS";
export const updateEmployeeStatus = createAction(UPDATE_EMPLOYEE_STATUS);

export const EMPLOYEE_RESET_PASSWORD = "EMPLOYEE_RESET_PASSWORD";
export const employeeResetPassword = createAction(EMPLOYEE_RESET_PASSWORD);

export const FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS";
export const fetchTransactions = createAction(FETCH_TRANSACTIONS);

export const CARD_PREFERENCES = "CARD_PREFERENCES";
export const cardPreferences = createAction(CARD_PREFERENCES);

export const TRANSACTION_RECEIPT = "TRANSACTION_RECEIPT";
export const transactionReceipt = createAction(TRANSACTION_RECEIPT);

export const ASSIGNED_CARDS = "ASSIGNED_CARDS";
export const assignedCards = createAction(ASSIGNED_CARDS);

export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const updateEmployee = createAction(UPDATE_EMPLOYEE);

export const GET_CARD_PREFERENCES = "GET_CARD_PREFERENCES";
export const getCardPreferences = createAction(GET_CARD_PREFERENCES);

export const SET_CARD_PREFERENCES = "SET_CARD_PREFERENCES";
export const setCardPreferences = createAction(SET_CARD_PREFERENCES);

export const SET_CARD_STATUS = "SET_CARD_STATUS";
export const setCardStatus = createAction(SET_CARD_STATUS);

export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const addEmployee = createAction(ADD_EMPLOYEE);

export const CARD_ISSUE = "CARD_ISSUE";
export const cardIssue = createAction(CARD_ISSUE);

export const PAYTABS_PRODUCT_LIST = "PAYTABS_PRODUCT_LIST";
export const paytabsProductList = createAction(PAYTABS_PRODUCT_LIST);
