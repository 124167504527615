import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  company: null,
  cardsList: [],
  preferences: {},
  cardStatus: [],
  cardPreference: [],
};

export const companyslice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state = initialState, { payload }) => {
      return {
        ...state,
        company: payload,
      };
    },

    cardsList: (state = initialState, { payload }) => {
      return {
        ...state,
        cardsList: payload,
      };
    },
    preferences: (state = initialState, { payload }) => {
      return {
        ...state,
        preferences: payload,
      };
    },
    setCardStatus: (state = initialState, { payload }) => {
      return {
        ...state,
        cardStatus: payload,
      };
    },
    setCardPreference: (state = initialState, { payload }) => {
      return {
        ...state,
        cardPreference: payload,
      };
    },
  },
  extraReducers: {},
});
